
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import Container from "typedi";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Services from "@services/Services";
import AccountInteractor from "@interactors/account/Account.interactor";

export default defineComponent({
    name: "account-create",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    setup() {
        const store = useStore();
        const submitButton = ref<HTMLElement | null>(null);
        const countries = Services.getStaticData().getCountries();
        const countriesPhonePrefix =
            Services.getStaticData().getCountriesPhonePrefix();
        const registrationDetails = ref<any>({
            nameFirst: "",
            nameLast: "",
            email: "",
            country: "United Kingdom",
            phoneCountryCode: "GB",
            phoneDialCode: "",
            phoneDialCodeLength: 0,
            phoneNumber: "",
            phoneNumberValidation: false,
            password: "",
            passwordConfirm: "",
            terms: false,
        });

        //Create form validation object
        const phoneRegExp =
            /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        const registrationScheme = Yup.object().shape({
            name: Yup.string().min(5).max(64).required().label("First Name"),
            surname: Yup.string()
                .min(5)
                .max(64)
                .required()
                .label("Second Name"),
            email: Yup.string()
                .min(3)
                .max(320)
                .required()
                .email()
                .label("Email"),
            password: Yup.string().min(5).max(256).required().label("Password"),
            country: Yup.string().required().label("Country"),
            phoneNumberWithDialCode: Yup.string()
                .matches(phoneRegExp, "Phone number is not valid")
                .required()
                .max(26)
                .label("Phone Number"),
            terms: Yup.boolean()
                .oneOf([true], "Terms of Service must be accepted")
                .label("Terms of Service"),
            passwordConfirm: Yup.string()
                .min(5)
                .required()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .label("Password Confirmation"),
        });

        const originUrl = window.location.origin;
        const logo = "/media/logos/new-login-logo.svg";

        return {
            messagesError: computed(() => store.getters.getErrors),
            logo,
            originUrl,
            registrationScheme,
            submitButton,
            countries,
            countriesPhonePrefix,
            registrationDetails,
        };
    },
    created: async function () {
        await this.setupTimezone();
    },
    mounted: function () {
        this.setupMeter();
    },
    computed: {
        phoneNumberWithDialCode: function (): string {
            let phoneNumber: string = this.registrationDetails.phoneDialCode;

            if (phoneNumber.length > 0) {
                phoneNumber += " ";
            }

            // Remove first whitespace
            phoneNumber += this.registrationDetails.phoneNumber.replace(
                /^\s*/,
                ""
            );

            // Remove last whitespace
            phoneNumber = phoneNumber.replace(/\s*$/, "");

            // Reduce white spaces to one
            phoneNumber = phoneNumber.replace(/[\s]{2,}/g, " ");

            return phoneNumber;
        },
    },
    methods: {
        onSubmitLogin: async function (values): Promise<void> {
            if (this.submitButton) {
                // Activate indicator
                this.submitButton.setAttribute("data-kt-indicator", "on");
            }

            // Call login interactor
            try {
                await Container.get(AccountInteractor).createAccount(
                    values.email,
                    values.password,
                    values.name,
                    values.surname,
                    this.phoneNumberWithDialCode,
                    values.country
                );
            } catch (error) {
                this.submitButton?.removeAttribute("data-kt-indicator");
            }
        },
        onChangePhoneDialCode: function (event: any) {
            const countriesPhonePrefix =
                Services.getStaticData().getCountriesPhonePrefix();
            const value = event.target.value;

            this.registrationDetails.phoneDialCode = countriesPhonePrefix.find(
                (country) => country.code === value
            )?.dial_code;
            this.registrationDetails.phoneDialCodeLength =
                this.calcStringLength(this.registrationDetails.phoneDialCode);
        },
        onKeyDownPhoneNumber: function (event: any) {
            const key = String(event.key);
            const isNumber = key.match(/[\d\s]/g);

            this.registrationDetails.phoneNumberValidation = true;

            if (isNumber === null) {
                event.preventDefault();
                return;
            }

            return true;
        },
        calcStringLength: function (value: string): number {
            return (value.match(/[+\d]/g) || []).length;
        },
        setupTimezone: async function () {
            const countriesPhonePrefix =
                Services.getStaticData().getCountriesPhonePrefix();
            const timezoneDetails =
                await Services.getTimezone().getTimezoneDetails();

            this.registrationDetails.country = timezoneDetails.country_name;
            this.registrationDetails.phoneCountryCode = timezoneDetails.country;
            this.registrationDetails.phoneDialCode = countriesPhonePrefix.find(
                (country) => country.code === timezoneDetails.country
            )?.dial_code;
            this.registrationDetails.phoneDialCodeLength =
                this.calcStringLength(this.registrationDetails.phoneDialCode);
        },
        setupMeter: function () {
            const meterElement = document.getElementById("kt-password-meter");

            if (meterElement) {
                new PasswordMeterComponent(
                    meterElement,
                    {
                        minLength: 5,
                        checkUppercase: true,
                        checkLowercase: true,
                        checkDigit: true,
                        checkChar: true,
                        scoreHighlightClass: "active",
                    },
                    {
                        componentName: "password-meter",
                        instanseQuery: "[data-kt-password-meter]",
                        inputQuery: "input[type]",
                        visibilityQuery:
                            '[data-kt-password-meter-control="visibility"]',
                        highlightQuery:
                            '[data-kt-password-meter-control="highlight"]',
                    }
                );
            }
        },
    },
});
