import { Inject, Service } from "typedi";
import AccountCreateModule from "./modules/AccountCreate.module";

@Service()
export default class AccountInteractor {
    public constructor(
        @Inject() private readonly _accounCreateModule: AccountCreateModule,
    ) {}

    // ---> Actions
    public async createAccount(
        email: string,
        password: string,
        nameFirst: string,
        nameLast: string,
        phoneNumber: string,
        country: string,
    ): Promise<void> {
        await this._accounCreateModule.createAccount(
            email,
            password,
            nameFirst,
            nameLast,
            phoneNumber,
            country,
        )
    }
}
