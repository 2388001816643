import { Mutations } from "@frameworks/vue/store/enums/StoreEnums";
import VueFramework from "@frameworks/vue/Vue.framework";
import LoginInteractor from "@interactors/login/Login.interactor";
import ApiService from "@services/api/Api.service";
import RESPONSE_STATUS from "@services/api/constants/RESPONSE_STATUS";
import IApiBaseResponse from "@services/api/types/IApiBaseResponse";
import ITimeZone from "@services/static-data/types/ITimeZone";
import TimezoneService from "@services/timezone/Timezone.service";
import { Inject, Service } from "typedi";

@Service()
export default class AccountCreateModule {
    public constructor(
        @Inject() private readonly _apiService: ApiService,
        @Inject() private readonly _timezoneService: TimezoneService,
        @Inject() private readonly _vueFramework: VueFramework,
        @Inject() private readonly _loginInteractor: LoginInteractor,
    ) {}

    // ---> Actions
    public async createAccount(
        email: string,
        password: string,
        nameFirst: string,
        nameLast: string,
        phoneNumber: string,
        country: string,
    ): Promise<void> {
        const timezone: ITimeZone | null = await this._timezoneService.getTimezone();

        if (!timezone) {
            throw new Error("Timezone is not defined");
        }

        try {
            // 1. Send request to api
            const result = await this._apiService.createAccount(
                email,
                password,
                nameFirst,
                nameLast,
                phoneNumber,
                country,
                timezone,
            );
            
            // 2. Handle response
            this.handleLoginResponse(result);

            // 3. Clear error messages
            this._vueFramework.getStore().commit(Mutations.SET_ERROR, []);

            // 4. Login
            await this._loginInteractor.login(
                email,
                password,
                true,
            );
        } catch (e) {
            console.error('CREATE_ACCOUNT', e);
            this._vueFramework.getStore().commit(Mutations.SET_ERROR, [(<Error>e).message]);

            throw e;
        }
    }

    private handleLoginResponse(data: IApiBaseResponse): void {
        if (data.status === RESPONSE_STATUS.BAD_REQUEST) {
            throw new Error(data.data.message);
        }

        if (data.status === RESPONSE_STATUS.FORBIDDEN) {
            throw new Error("Unauthorized");
        }
    }
}
